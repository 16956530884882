import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const GOOGLE_RECAPTCHA_KEY = process.env.GOOGLE_RECAPTCHA_KEY;

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}
